import './App.css';
import SearchInput from "./components/SearchInput/SearchInput";

function App() {
  return (
    <div className="App">
      <SearchInput />
    </div>
  );
}

export default App;
