import React, { FC, FormEvent, useEffect, useState } from "react";
import "./SearchInput.css";
import { useDebounce } from "../../utils/hooks/UseDebounce";
import md5 from "blueimp-md5";

interface SearchInputProps {}

type CountryCode = "CA" | "US";

const SearchInput: FC<SearchInputProps> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [highlighting, setHighlighting] = useState({});
  const [showPopup, setShowPopup] = useState(true);
  const [countryCode, setCountryCode] = useState<CountryCode>("CA");
  const debouncedQuery = useDebounce(searchQuery, 500);

  const searchForCitations = async (query: string) => {
    const provider = countryCode === "CA" ? "compass" : "freelaw";
    const resp = await fetch(
      `https://api.jurisage.com/solr/citations/select?df=citation&hl.fragsize=500&defType=dismax&q.op=AND&hl.fl=citation&hl.requireFieldMatch=true&hl=true&q=${query}&fq=provider:${provider}`
    );
    const data = await resp.json();
    return data;
  };

  useEffect(() => {
    const storedCountryCode = localStorage.getItem("countryCode");
    if (storedCountryCode) {
      setCountryCode(storedCountryCode as CountryCode);
    }
    if (debouncedQuery) {
      setShowPopup(true);
      searchForCitations(debouncedQuery).then((results) => {
        const data = results.response.docs;
        const highlighting = results.highlighting;
        setResults(data);
        setHighlighting(highlighting);
      });
    } else {
      setShowPopup(false);
      setResults([]);
      setHighlighting({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const handleClearClick = () => {
    setSearchQuery("");
    setShowPopup(false);
  };

  const handleCountryCodeSelect = (countryCode: CountryCode) => {
    setCountryCode(countryCode);
    localStorage.setItem("countryCode", countryCode);
    setShowPopup(false);
  };

  const formatCitation = (result: any) => {
    const highlightedResult = (highlighting as any)[result.id].citation.filter((x: any) => x);
    if (highlightedResult) {
      return highlightedResult;
    }
    return result.citation[0];
  };

  function generateJCD(citationId: string) {
    const provider = countryCode === "CA" ? "compass" : "freelaw";
    return `JCD-${provider === "compass" ? "01" : "02"}-${md5(provider + citationId)}`;
  }

  function getCitationIdFromResult(result: any) {
    return countryCode === "CA" || result.id.startsWith("JCD-") ? result.id : generateJCD(result.case_id);
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (results.length > 0 && showPopup) {
      // Select first option and navigate to it
      setShowPopup(false);
      window.location.href = `https://jurisage.com/dashboard/${getCitationIdFromResult(results[0])}`;
    }
  };

  return (
    <>
      <span className={"CountryToggle " + (countryCode === "CA" ? "CountryToggle--CA" : "CountryToggle--US")}>
        <button className={"CountryToggle__Inner"} onClick={() => handleCountryCodeSelect("CA")}>
          CA
        </button>
        <button className={"CountryToggle__Inner"} onClick={() => handleCountryCodeSelect("US")}>
          US
        </button>
      </span>
      <span className="Search">
        <svg className="SearchInput__Icon" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_102_2)">
            <path d="M49.6794 57.5794C44.5366 61.2422 38.2466 63.3994 31.4494 63.3994C14.0816 63.3994 -0.000640869 49.3172 -0.000640869 31.9494C-0.000640869 14.5816 14.0816 0.499367 31.4494 0.499367C48.8172 0.499367 62.8994 14.5816 62.8994 31.9494C62.8994 38.7464 60.7422 45.0364 57.0794 50.1794L77.744 70.844C79.779 72.879 79.7641 76.1388 77.744 78.1548L77.6589 78.2398C75.6462 80.2525 72.3641 80.26 70.3481 78.2398L49.6835 57.5752L49.6794 57.5794ZM31.4494 55.9994C44.7322 55.9994 55.4994 45.2322 55.4994 31.9494C55.4994 18.6666 44.7322 7.89943 31.4494 7.89943C18.1666 7.89943 7.39936 18.6666 7.39936 31.9494C7.39936 45.2322 18.1666 55.9994 31.4494 55.9994Z" fill="currentColor"/>
          </g>
          <defs>
            <clipPath id="clip0_102_2">
              <rect width="80.0006" height="80" fill="white" transform="translate(-0.000640869)"/>
            </clipPath>
          </defs>
        </svg>
        <form className="SearchInput__Form" onSubmit={handleSubmit}>
          <input
            placeholder="Enter a citation..."
            className="SearchInput"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </form>
        {searchQuery.length > 0 && (
          <button className="SearchInput__Close" onClick={handleClearClick}>
            &times;
          </button>
        )}
        {showPopup && results.length > 0 && (
          <div className="Search__Results">
            <ul className="Citations">
              {results.map((result: any) => (
                <button
                  key={result.id}
                  onClick={() => {
                    setShowPopup(false);
                    window.location.href = `https://jurisage.com/dashboard/${getCitationIdFromResult(result)}`;
                  }}
                >
                  {<span dangerouslySetInnerHTML={{ __html: formatCitation(result) }}></span>}
                </button>
              ))}
            </ul>
          </div>
        )}
      </span>
    </>
  );
};

export default SearchInput;